<template>
	<div class="resume-wrap content-wrap">
		<Spin fix v-if="loading"></Spin>
		<!-- 基本信息 -->
		<CtmsAction>
			<Button type="primary" @click="handleEdit">修改基本信息</Button>
			<Button @click="handlePassword">修改登录密码</Button>
		</CtmsAction>
		<div style="padding: 20px 12px">
			<Card>
				<p slot="title">基本信息</p>
				<Form :model="viewData" ref="form" label-position="top">
					<Row :gutter="32">
						<!--用户名-->
						<Col span="12">
							<FormItem label="用户名">
								{{ viewData.username || "--" }}
							</FormItem>
						</Col>
						<!--真实姓名-->
						<Col span="12">
							<FormItem label="真实姓名">
								{{ viewData.realName || "--" }}
							</FormItem>
						</Col>
						<!--手机号码-->
						<Col span="12">
							<FormItem label="手机号码">
								{{ viewData.phone || "--" }}
							</FormItem>
						</Col>
						<!--组织-->
						<Col span="12">
							<FormItem label="组织">
								{{ viewData.orgName || "--" }}
							</FormItem>
						</Col>
						<!--部门-->
						<Col span="12">
							<FormItem label="部门">
								{{ viewData.deptName || "--" }}
							</FormItem>
						</Col>
						<!--职称-->
						<Col span="12">
							<FormItem label="职称">
								{{ viewData.jobName || "--" }}
							</FormItem>
						</Col>
						<!--性别-->
						<Col span="12">
							<FormItem label="性别">
								{{ viewData.sexName || "--" }}
							</FormItem>
						</Col>
						<!--微信-->
						<Col span="12">
							<FormItem label="微信">
								{{ viewData.wx || "--" }}
							</FormItem>
						</Col>
						<!--CA编号-->
						<Col span="12">
							<FormItem label="CA编号">
								{{ viewData.caUuid || "--" }}
							</FormItem>
						</Col>
						<!--工号-->
						<Col span="12">
							<FormItem label="工号">
								{{ viewData.jobNumber || "--" }}
							</FormItem>
						</Col>
						<!--更新人-->
						<Col span="12">
							<FormItem label="更新人">
								{{ viewData.updatedBy }}
							</FormItem>
						</Col>
						<!--更新时间-->
						<Col span="12">
							<FormItem label="更新时间">
								{{ viewData.updatedTime }}
							</FormItem>
						</Col>
						<Col span="24">
							<FormItem prop="identityBack" label="身份证头像面">
								<img
									:src="getPreviewImg(viewData.identityBack)"
									class="preview"
									v-if="viewData.identityBack"
								/>
								<span v-else>--</span>
							</FormItem>
						</Col>
						<Col span="24">
							<FormItem prop="identityFront" label="身份证国徽面">
								<img
									:src="getPreviewImg(viewData.identityFront)"
									class="preview"
									v-if="viewData.identityFront"
								/>
								<span v-else>--</span>
							</FormItem>
						</Col>
						<Col span="24" v-if="isIdentityRequired">
							<FormItem prop="businessProxy" label="单位委托书">
								<img
									:src="getPreviewImg(viewData.businessProxy)"
									class="preview"
									v-if="viewData.businessProxy"
								/>
								<span v-else>--</span>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</Card>
		</div>

		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleEditOk"
			:jobList="dictionaryList[7]"
			:sexList="dictionaryList[5]"
			:id="info.id"
			:type="'personalInfo'"
		></Add>
		<!--修改密码-->
		<Password
			:visible="changePasswordVisible"
			@onCancel="changePasswordVisible = false"
		></Password>
	</div>
</template>

<script>
import "./style.less"
import { mapState } from "vuex"
import Add from "@/views/Setting/Staff/Add.vue"

import api from "@/api/setting/staff"
import publicApi from "@/api/public"
import Password from "./Password.vue"

const { publicGetDictionaryList, publicGetOrganizationList } = publicApi
const { apiGet } = api
export default {
	components: {
		Add,
		Password
	},
	data() {
		return {
			viewData: {},
			loading: false,
			isIdentityRequired: false,
			changePasswordVisible: false,
			addVisible: false,
			// 数据字典
			dictionaryList: [],
			// 组织列表
			organizationList: []
		}
	},
	computed: {
		...mapState("user", ["info"])
	},
	created() {
		this.getPublicData()
	},
	mounted() {
		this.$nextTick(() => {
			this.init()
		})
	},
	methods: {
		getPublicData() {
			// 获取数据字典
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.dictionaryList = res.data
				}
			})
			// 获取组织列表
			this.$asyncDo(async () => {
				const res = await publicGetOrganizationList()
				if (res) {
					this.organizationList = res.data
				}
			})
		},
		init() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGet({ id: this.info.id })
				if (res) {
					this.isIdentityRequired =
						["101", "102", "103"].indexOf(res.data.orgType) > -1
					this.viewData = {
						...this.viewData,
						...res.data
					}
				}
				this.loading = false
			})
		},
		// 修改信息显示
		handleEdit() {
			this.addVisible = true
		},
		// 修改成功刷新頁面
		handleEditOk() {
			this.addVisible = false
			this.$store.dispatch("user/getInfo")
			this.init()
		},
		// 修改密码显示
		handlePassword() {
			this.changePasswordVisible = true
		},
		onCancel() {
			this.$emit("onCancel")
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		}
	}
}
</script>

<style lang="less">
.sign-wrap {
	display: flex;
	.sign-info {
		flex-basis: 216px;
		width: 216px;
		.info-item {
			margin-bottom: 15px;
			h2 {
				margin-bottom: 10px;
			}
			.img {
				width: 200px;
				height: 80px;
				.warning {
					color: #ff4e00;
				}
				img {
					width: 100%;
					height: 100%;
					border: 1px solid #ccc;
				}
			}
		}
	}
	.sign-design {
		flex: 1;
		padding-left: 16px;
		border-left: 1px solid #ccc;
		.sign-content {
			height: 500px;
		}
		.upload-info {
			line-height: 30px;
			color: #ff4e00;
		}
		.action-button {
			padding: 10px 0;
			text-align: center;
			.ivu-btn {
				margin: 0 10px;
			}
		}
		.sign-footer {
			text-align: right;
			.ivu-btn {
				margin-right: 10px;
			}
		}
	}
}
</style>
